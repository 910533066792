import { injectGlobal } from 'emotion';
import emotionNormalize from 'emotion-normalize';

injectGlobal`
  ${emotionNormalize}

  *, *::after, *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  html {
    font-family: Inter, sans-serif;
  }

  html, body, #__next, #root{
    width: 100%;
    min-height: 100vh;
  }

  #__next {
    display: flex;
    flex-direction: column;
  }

  p {
    margin: 0 0 10px;
  }

  .removed {
    display: none !important;
  }
`;
