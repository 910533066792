type Directive = string;
type Value = string;
interface Options {
  devOnly?: boolean;
  prodOnly?: boolean;
}

/**
 * Generate Content-Security-Policy header value. Can be used with CSP or CSPRO.
 * @param nonce Nonce value to be allowed by CSP.
 * @param datadogToken Token to use for datadog reporting.
 * @param existingCSP Existing stringified CSP values (assumes they need to be prepended)
 * @returns stringified CSP value that can be added to the CSP/CSPRO header.
 */
function generateCSP(
  nonce: string,
  datadogToken: string,
  existingCSP?: string,
) {
  const policy: Partial<Record<Directive, Value[]>> = {};

  // adder function for our policy object
  const add = (directive: Directive, value: Value, options: Options = {}) => {
    if (options.devOnly && process.env.NODE_ENV !== 'development') {
      return;
    }
    if (options.prodOnly && process.env.NODE_ENV !== 'production') {
      return;
    }
    const curr = policy[directive];
    policy[directive] = curr ? [...curr, value] : [value];
  };

  // script-src
  add('script-src', `'unsafe-eval'`, { devOnly: true });
  add('script-src', "'self'");
  add('script-src', "'report-sample'");
  add('script-src', "'strict-dynamic'"); //  allows the execution of scripts dynamically added to the page, as long as they were loaded by a safe, already-trusted script
  add('script-src', `'nonce-${nonce}'`);

  // Style-src
  add('style-src', "'self' 'unsafe-inline'");
  add('style-src', 'https://fonts.googleapis.com');
  add('style-src', '*.circleci.com');

  // Frame-ancestors
  add('frame-ancestors', "'self'");

  // Reporting
  const datadogReportURI = `https://browser-intake-datadoghq.com/api/v2/logs?dd-api-key=${datadogToken}&dd-evp-origin=content-security-policy&ddsource=csp-report`;

  // report-uri (being deprecated, but still supported by firefox)
  add('report-uri', datadogReportURI);
  // report-to (not supported by firefox yet (2024/02/23))
  add('report-to', datadogReportURI);

  // upgrade-insecure-requests
  add('upgrade-insecure-requests', '', { prodOnly: true });

  // Build csp string
  const csp = Object.entries(policy)
    .map(([key, value]) => {
      return `${key} ${value?.join(' ')}`;
    })
    .join('; ');

  // return the object in a formatted value
  return existingCSP ? existingCSP + '; ' + csp : csp;
}

export default generateCSP;
